import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SignUpEmail from "../components/signUpEmail"

import img1 from "../images/about/about-img-1.svg";
import img2 from "../images/about/about-img-2.svg";
import img3 from "../images/about/about-img-3.svg";
import img4 from "../images/about/about-img-4.svg";
import img5 from "../images/about/about-img-5.svg";
import img6 from "../images/about/about-img-6.svg";
import img7 from "../images/about/about-img-7.svg";
import img8 from "../images/about/about-img-8.svg"
import img9 from "../images/about/about-img-9.svg";
import img10 from "../images/about/about-img-10.svg";
import img11 from "../images/about/about-img-11.svg";
import img12 from "../images/about/about-img-12.svg";
import img13 from "../images/about/about-img-13.svg";
import img14 from "../images/about/about-img-14.svg";
import img15 from "../images/about/about-img-15.svg";

const AboutPage = () => (
  <Layout css="aboutPageCss">
    <SEO title="Internet Marketing Services In Boston"
         description={"Looking for the best internet marketing services in Boston? Let our team of experts help you crush your digital marketing goals and crush the competition."}/>
    <div className={"container hero section-padding-wrapper"}>
      <div className={"row align-items-center m-flex-direction-col-rev"}>
        <div className={"col-md-6"}>
          <h1>ABOUT OUR TOP <span className={"red"}>DIGITAL MARKETING</span> AGENCY</h1>
          <p className={"subtitle"}>Avantcy Marketing is a top digital marketing agency driven by our love for helping
            people. We are passionate about seeing other businesses thrive, and we love to be the channel that pushes
            that goal forward. We achieve this by providing digital marketing solutions with custom strategies for every
            one of our clients.</p>
          <a href={"/contact#contact-today"} className={"button-red"}>REQUEST A CALL</a>
        </div>
        <div className={"col-md-6"}>
          <img alt={""} src={img1} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2>OUR BRAND MESSAGE</h2>
      </div>
      <h3 className={"subtitle"}>Our brand seeks to connect your business with the world around you. Not just that, we
        strive to ensure that you get the user reach you aim to attain. Although we are a top digital marketing agency
        in
        Boston, we want to ensure that your services go beyond the United State's borders. We look forward to
        incorporating every advancement in our strategies to provide satisfactory results for our clients.</h3>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"row justify-content-center m-flex-direction-col-rev"}>
        <div className={"col-md-7 p-top-20 p-bottom-20"}>
          <p className={"black-text align-items-center"}>It is a fact that we are a modern industry and can adapt to
            recent trends, brands, and industries.
            However, that isn't the only reason why we adopt more than one digital marketing strategy. Some other
            Reasons we offer these many services are:</p>
          <ul className={"checkmark list-style-none"}>
            <li>Each marketing channel has its advantage.</li>
            <li>These digital marketing channels work best when we combine them strategically with each other in
              sets.
            </li>
            <li>We want to offer clients a set of strategies to connect for better visibility leading to more
              revenue.
            </li>
          </ul>
        </div>
        <div className={"col-md-5"}>
          <img alt={""} src={img8} className={"w-100 m-pic-align"}/>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"row justify-content-center"}>
        <div className={"col-md-5 flex-vert-center-wrapper"}>
          <img alt={""} src={img13} className={"w-100 m-pic-align"}/>
        </div>
        <div className={"col-md-7"}>
          <div className={"text-align-center"}>
            <h2>WHO ARE WE</h2>
          </div>
          <p className={"black-text align-items-center"}>Avantcy Marketing is a top digital marketing agency. We do
            our best to provide creative solutions and resources via:</p>
          <ul className={""}>
            <li><strong>Service automation</strong></li>
            <li><strong>Digital marketing</strong></li>
            <li><strong>Consulting services</strong></li>
          </ul>
          <p className={"black-text align-items-center"}>Avantcy Marketing is a team of highly skilled digital
            marketers that show high adaptability to changing trends to help you win in digital marketing. We are a
            group of motivated expert digital marketers driven by our love for helping people.</p>
          <p className={"black-text align-items-center"}>One of the reasons we have lasted long is because making a
            difference in your business keeps us thriving. We maintain our position among top content marketing
            agencies because of our love for consistency and innovation.</p>
          <p className={"black-text align-items-center"}>With many years of creative experience under our belt, we
            offer you the opportunity to work with the best of the best. Additionally, Avantcy Marketing continually
            aims to provide unique, innovative solutions for every business everywhere.</p>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile m-flex-direction-col-rev"}>
        <div className={"flex-equal-width m-3"}>
          <div className={"text-align-center"}>
            <h2>OUR MISSION</h2>
          </div>
          <p>At Avantcy Marketing, our core mission to help our clients
            accelerate their business. The platform we have to ensure that our client's businesses grow exponentially is
            the internet. Therefore, we use digital marketing to ensure that your brand is visible to your target
            audience to accelerate your business growth.</p>
        </div>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img15}/>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"d-flex justify-content-center flex-column-mobile"}>
        <div className={"flex-equal-width m-3 flex-vert-center-wrapper"}>
          <img alt={""} src={img14}/>
        </div>
        <div className={"flex-equal-width m-3"}>
          <div className={"text-align-center"}>
            <h2>OUR VALUES</h2>
          </div>
          <p className={"black-text align-items-center"}>In addition to our love for helping people achieve their goals,
            Avantcy Marketing values:</p>
          <ul className={""}>
            <li><strong>Professionalism</strong></li>
            <li><strong>Result-oriented solutions</strong></li>
            <li><strong>Integrity</strong></li>
            <li><strong>Authenticity</strong></li>
          </ul>
          <p className={"black-text align-items-center"}>We prioritize providing clients with professional,
            result-oriented solutions backed by 100% authenticity and integrity.</p>
        </div>
      </div>
    </div>
    <div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <div className={"text-align-center"}>
          <h2>WHY CHOOSE AVANTCY MARKETING</h2>
        </div>
        <h3 className={"subtitle"}>When you choose Avantcy Marketing as your expert digital marketing agency, you get
          more
          than a customized online marketing service.</h3>
        <div className={"row m-top-20"}>
          <div className={"col-md-6"}>
            <div className={"img-wrapper flex-vert-center-wrapper"}>
              <img alt={""} src={img9} id={""} className={"red-info-icon"}/>
            </div>
            <h4>You Choose Innovation</h4>
          </div>
          <div className={"col-md-6"}>
            <div className={"img-wrapper flex-vert-center-wrapper"}>
              <img alt={""} src={img10} id={""} className={"red-info-icon"}/>
            </div>
            <h4>You Choose Adaptability</h4>
          </div>
        </div>
        <div className={"row m-top-20"}>
          <div className={"col-md-6"}>
            <div className={"img-wrapper flex-vert-center-wrapper"}>
              <img alt={""} src={img11} id={""} className={"red-info-icon"}/>
            </div>
            <h4>You Choose Passion</h4>
          </div>
          <div className={"col-md-6"}>
            <div className={"img-wrapper flex-vert-center-wrapper"}>
              <img alt={""} src={img12} id={""} className={"red-info-icon"}/>
            </div>
            <h4>You Choose A Modern Team</h4>
          </div>
        </div>
      </div>
    </div>
    <div className={"container section-padding-wrapper"}>
      <div className={"text-align-center"}>
        <h2>MARKETING SERVICES</h2>
      </div>
      <h3 className={"subtitle"}>Are you searching for a team of innovative digital marketers, web designers, content
        creators, and more? Avantcy Marketing offers the best team for you that provides customizable tested plans to
        ensure you see results. Our ability to help a client grow exponentially reflects our passion, commitment, and
        expertise. Therefore, we take your trust in our brand seriously for the best results.</h3>
      <div className={"row m-top-20"}>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img2} id={""} className={"red-info-icon"}/>
          </div>
          <h4>Search Engine Optimization (SEO)</h4>
        </div>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img3} id={""} className={"red-info-icon"}/>
          </div>
          <h4>Pay-Per-Click Advertising (PPC)</h4>
        </div>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img4} id={""} className={"red-info-icon"}/>
          </div>
          <h4>Lead Management Services</h4>
        </div>
      </div>
      <div className={"row m-top-20"}>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img5} id={""} className={"red-info-icon"}/>
          </div>
          <h4>Content Marketing</h4>
        </div>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img6} id={""} className={"red-info-icon"}/>
          </div>
          <h4>Web Design and Development</h4>
        </div>
        <div className={"col-md-4"}>
          <div className={"img-wrapper flex-vert-center-wrapper"}>
            <img alt={""} src={img7} id={""} className={"red-info-icon"}/>
          </div>
          <h4>Social Media Marketing</h4>
        </div>
      </div>
    </div>
    {/*<div className={"background-gray"}>
      <div className={"container section-padding-wrapper"}>
        <SignUpEmail/>
      </div>
    </div>*/}
  </Layout>
);

export default AboutPage
